.blog h1 {
  line-height: 1.18;
  font-size: 50px;
  font-weight: bold;
}

.blog .section {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  margin: 18px 0;
}

.blog p {
  font-size: 18px;
  letter-spacing: 0.025em;
  margin-bottom: 16px;
}

.blog ul {
  list-style: disc;
  font-size: 18px;
  margin-left: 26px;
  list-style-position: outside;
}

.blog li {
  margin: 18px 0;
}

@media only screen and (max-width: 1000px) {
  .blog h1 {
    line-height: 1.18;
    font-size: 30px;
    font-weight: bold;
  }
}
